import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, List, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Copyright from '../../components/copyRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainClientListItems from './mainList';
import AuthContext from '../../navigation/context/authContext';

export default function ClientLayout(props)
{
    const {signOut} = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen,setIsOpen] = useState(false);

    const history = useHistory();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTogleDrawer = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <Box>
            <AppBar position='fixed' sx={{p:1,borderRadius:0}}>
                <Container maxWidth='xl'>
                <Toolbar variant='dense' disableGutters>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleTogleDrawer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        CIVO
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                open={isOpen}
            >
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',padding:0}}>
                    <Typography>CIVO</Typography>
                    <IconButton style={{marginLeft:155}} onClick={handleTogleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <MainClientListItems/>
                </List>
                <Divider />
                <List>
                    lista secundaria
                </List>
                <List onClick={()=>handleSignOut()}>
                    Salir
                </List>
            </Drawer>
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    mt:10,
                    minHeight: '85vh',
                    overflow: 'auto',
                }}
            >
                {props.children}
                <br/><br/>
            </Box>
            <Copyright/>
        </Box>
    )
}