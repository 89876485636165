import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Chip, Container, Grid, IconButton, Link, Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { GetAll } from "../../../controllers/EventsController";
import ROUTES from "../../../navigation/routes";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { GetComercios } from "../../../controllers/admin/ComerciosController";

export default function BusinessDataTable()
{
    const [comercios,setComercios] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        Get_Comercios();
    },[]);

    const redireccionar = (route,business) => {
        localStorage.setItem('CivoBusiness',JSON.stringify(business));
        history.push(route);
    }

    const Get_Comercios = async () => {
        let response = await GetComercios();
        
        if(response.success === true)
        {
            setComercios(response.data.comercios);
        }
    }


    const columns = [
        {
            name : '',
            label : 'Imagen',
            options : 
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:50,height:50,borderRadius:10}} src={ROUTES.URLImagenes + 'empresas/' + comercios[dataIndex].imagen2} alt={comercios[dataIndex].apellido}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'name',
            label : 'Comercio',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{comercios[dataIndex].apellido}</strong> </Typography>
                        </div>
                    );
                },
                filter: false,
                search: true
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRenderLite : (dataIndex) => (
                    comercios[dataIndex].estatus === 1
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' color='error' />
                )  
            }
        },
        
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <IconButton onClick={()=> redireccionar('comercios/'+comercios[dataIndex].apellido,comercios[dataIndex])}>
                                <RemoveRedEyeIcon/>
                            </IconButton>
                        </div>
                    );
                },

                print : false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        comercios !== []
        ?
        <MUIDataTable
            title='Listado de Lugares'
            columns={columns}
            data={comercios}
            options={options}
        />
        :null
    )
}