import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PasswordIcon from '@mui/icons-material/Password';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DevicesIcon from '@mui/icons-material/Devices';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';





export default function MainListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/company') } > 
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Empresa" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('/services')}>
                <ListItemIcon>
                    <FactCheckIcon />
                </ListItemIcon>
                <ListItemText primary="Servicios" />
            </ListItem>

            <ListItem button onClick={()=>redireccionar('/products')}>
                <ListItemIcon>
                    <LocalOffer />
                </ListItemIcon>
                <ListItemText primary="Productos" />
            </ListItem>

            

            <ListItem button onClick={()=>redireccionar('/seguridad')}>
                <ListItemIcon>
                    <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary="Seguridad" />
            </ListItem>
        
        {/** 
            <ListItem button>
                <ListItemIcon>
                <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reportes" />
            </ListItem>

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integraciones" />
            </ListItem>

            

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Transacciones" />
            </ListItem>
            */}
        </div>
    )    
}
    