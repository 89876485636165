import ROUTES from "../navigation/routes";
import { GET, POST } from "./pos_offline/ConsumeApi" //"./ConsumeApiController";

export async function search(comercio_id,cliente_id,fecha_inicio,fecha_fin)
{
    let params = [
        {name:'comercio_id',value:comercio_id},
        {name:'cliente_id',value:cliente_id},
        {name:'evento_id',value:7},
        {name:'fecha_inicio',value:fecha_inicio},
        {name:'fecha_fin',value:fecha_fin}
    ];
    console.log('La ruta es: ',ROUTES.POS_OFFLINE.TRANSACTIONS.Search)
    let response = await POST(ROUTES.POS_OFFLINE.TRANSACTIONS.Search,params);
    return response;
}