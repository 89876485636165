import { IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton(props)
{
    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    return(
        <IconButton onClick={()=>redireccionar(props.route)}>
            <ArrowBackIcon/>
        </IconButton>
    )
}