import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, TextField, Button, Paper, Divider } from "@mui/material";
import {LoadingButton} from '@mui/lab';
import AuthContext from '../../navigation/context/authContext';
import logo from '../../assets/img/logo.jpg';
import ThemeMode from "../../assets/theme/theme";
import ColorModeContext from "../../assets/theme/ColorModeContext";
import Copyright from "../../components/copyRight";


export default function SignIn()
{
    const [email,setEmail] = useState('');
    const [errorEmail,setErrorEmail] = useState(false);

    const [password,setPassword] = useState('');
    const [errorPassword,setErrorPassword] = useState(false);

    const [isLoading,setIsLoading] = useState(false);

    const { signIn } = useContext(AuthContext);


    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }

    const checkForm = () => {
        var ban = 0;

        if(email === '')
        {
            setErrorEmail(true);
            ban = 1;
        }

        if(password === '')
        {
            setErrorPassword(true);
            ban = 1
        }

        return ban;
    }
    

    /**
     * this function checks if all input fields of the form are filled
     * then if the form fields are full, execute the submit
     */
     const handleSubmit = async() => {
        
        if(checkForm() === 0)
        {
            await setIsLoading(true)
            await signIn({email,password});
            await setIsLoading(false);
        }
    }



    return(
        <Box
            sx={{
                display:'flex',
                flexDirectio:'row',
                bgcolor:'background.primary'
            }}
        >
            <Grid container style={{display:'flex', flexDirection:'row',justifyContent:'center'}}>
                
                <Grid item xs={12} lg={6} style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    {/**<div style={{position:'absolute',top:20,right:20}}>
                        <ThemeMode mode={ColorModeContext}/>
                    </div>*/}
                    <Grid container spacing={3} style={{justifyContent:'center'}}>
                        <Grid item xs={10} sm={8} md={6} lg={8} xl={8}>
                            <Paper elevation={4} sx={{mt:5}}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { width: '100%',borderColor:'#FFF' },
                                        
                                        display:'flex',
                                        flexDirection:'column',
                                        marginBottom:3,
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <img src={logo} style={{width:90}} alt='logo'/>
                                    <Typography variant='body1'  style={{marginTop:30,marginBottom:30,color:'#3c4257',fontSize:24,fontWeight:'bold'}}>
                                        Inicie sesión en su cuenta
                                    </Typography>
                                    <TextField
                                        color='primary'
                                        label="Email" 
                                        variant="outlined"
                                        id="email"
                                        name='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={ () => (email === '' ? setErrorEmail(true) : setErrorEmail(false) ) }
                                        error = {errorEmail}
                                        helperText={errorEmail && 'Ingrese el correo electronico'}
                                    />
                                    <TextField
                                        style={{marginTop:30,marginBottom:30}}
                                        color='primary'
                                        id="password"
                                        name='password'
                                        label="Password" 
                                        variant="outlined"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onBlur={ () => (password === '' ? setErrorPassword(true) : setErrorPassword(false) ) }
                                        error = { errorPassword }
                                        helperText={errorPassword && 'Ingrese la contrasena'}
                                    />
                                    <LoadingButton
                                        color='primary'
                                        loading={isLoading}
                                        fullWidth
                                        variant="contained"
                                        onClick={()=>handleSubmit()}
                                    >
                                        Log In
                                    </LoadingButton>
                                    
                                </Box>
                                <Divider sx={{mt:5,mb:1}}/>
                                <div style={{display:'flex',flexDirection:"column",alignItems:'start'}}>
                                    <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/forgotpassword')}>
                                        <Typography variant='subtitle2' color='text.primary'>¿Se te olvidó tu contraseña?</Typography>
                                    </Button>
                                    <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/signup')}>
                                        <Typography variant='subtitle2' color='text.primary'>Registrate</Typography>
                                    </Button>
                                    {/** 
                                    <Button variant="text" color='secondary' size='small' style={{textTransform:'none'}} onClick={()=>redirect('/verify')}>
                                        <Typography variant='subtitle2'>Verifica tu usuario</Typography>
                                    </Button>*/}
                                </div>
                            </Paper>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}