import React from 'react';
import { Breadcrumbs, Button, Container, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../../components/addButton';
import BackButton from '../../../../components/backButton';
import CategoriasEmpresaDataTable from './table';

export default function CategoriasEmpresa()
{

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        aria-current="page"
                        onClick={()=>redireccionar('/products')}
                    >
                        Productos
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Categorías
                    </Link>
                </Breadcrumbs>
                <BackButton route='/products'/>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Categorías
            </Typography>
            <AddButton route='/products/categories/add'/>
            <CategoriasEmpresaDataTable/>
        </Container>
    )
}