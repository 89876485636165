import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Button, Container, Link, Typography } from '@mui/material';
import BusinessDataTable from './table';
import { useHistory } from 'react-router-dom';


export default function Business()
{
    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        href="/lugares"
                        aria-current="page"
                    >
                        Concecionarios
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h4' style={{textAlign:'center'}}>Concecionarios</Typography>
            {
                /**
                 * <Button variant='contained'>
                Agregar
            </Button>
                 */
            }
            <br/><br/>
            <Button variant='contained' onClick={()=>redireccionar('comercios/agregar')}>
                Registrar Concecionario
            </Button>
            <br/><br/>
            <BusinessDataTable/>
        </Container>
    )
}