import { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, FormControl, Grid, InputAdornment, MenuItem, Paper, Snackbar, TextField, Typography } from "@mui/material";

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article'; //rif
import ListIcon from '@mui/icons-material/List';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import UserContext from "../../../navigation/context/userContext";
import { GetCategorias } from "../../../controllers/admin/CategoriasController";
import { LoadingButton } from "@mui/lab";

import { Save } from '@mui/icons-material';
import { updateDatosEmpresa } from "../../../controllers/ShopController";

export default function ProfileInfo()
{
    const {userData,setUserData} = useContext(UserContext);
    const [isEditing,setIsEditing] = useState(false);

    const [categorias,setCategorias] = useState([]);
    const [categoria,setCategoria] = useState(userData.user.idTipoEmpresa);
    const [errorCategoria,setErrorCategoria] = useState(false);

    const [nombreComercial,setNombreComercial] = useState(userData.user.apellido);
    const [errorNombreComercial,setErrorNombreComercial] = useState(false);

    const [descripcion,setDescripcion] = useState(userData.user.descripcion);
    const [errorDescripcion,setErrorDescripcion] = useState(false);

    const [isLoadingSave,setIsLoadingSave] = useState(false);
    const [openAlert,setOpenAlert] = useState(false);
    const [error,setError] = useState(false);
    const [msg,setMsg] = useState('');

    useEffect(()=>{
        getCategories();        
    },[]);

    const getCategories = async () => {
        let response = await GetCategorias();
        if(response.success === true)
        {
            setCategorias(response.data.categorias);
        }
    }


    const formVerification = () => {
        var ban = 0;

        /*if(razonSocial === '')
        {
            setState({field:'errorRazonSocial',value:true});
            ban = 1;
        }*/

        
        if(nombreComercial === '')
        {
            setErrorNombreComercial(true);
            ban = 1;
        }

        if(descripcion === '')
        {
            setErrorDescripcion(true);
            ban = 1;
        }
            
        /*if(rif === '' || rifReg.test(rif) === false)
        {
            setState({field:'errorRif',value:true});
            ban = 1;
        }*/
        
        /*if(categoria === '')
        {
            setErrorCategoria(true)
            ban = 1;
        }*/
        
        /*if(telefono === '' || telefonoReg.test(telefono) === false)
        {
            setState({field:'errorTelefono',value:true});
            ban = 1;
        }*/

        //Direccion y Coordenadas
        /*if(direccion === '')
        {
            setState({field:'errorDireccion',value:true});
            ban = 1;
        }*/

        /** 
        if(coordenadas == null)
        {
            setState({field:'errorCoordenadas',value:true});
            ban = 1;
        }*/

        console.log(ban)
        return ban;
    }


    const companyUpdate = async () => {
        setIsLoadingSave(true);

        console.log('Los datos son: ',nombreComercial)
        
        let response = await updateDatosEmpresa(nombreComercial,descripcion);

        if(response.success === true)
        {
            setError(false);
            setMsg(response.message);
            setOpenAlert(true);
        }
        else
        {   
            setError(true);
            setMsg(response.error);
            setOpenAlert(true);
        }

        setIsLoadingSave(false);
    }

    const handleSubmit = async () => {
        if(formVerification() === 0)
            companyUpdate();
    }

    return(
        <Paper elevation={4}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography variant='h6'>Datos Generales</Typography>
                <LoadingButton
                    loading={isLoadingSave}
                    onClick={()=>handleSubmit()}
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="outlined"
                >
                    Actualizar
                </LoadingButton>
            </div>
            <br/>
            <Grid container>
                <Grid item lg={12}>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/**Razon Social */}
                                <TextField
                                    fullWidth
                                    disabled
                                    id="razonSocial"
                                    name='razonSocial'
                                    label="Razon Social"
                                    placeholder='Razon Social'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <WorkIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value = {userData.user.name}
                                    //onChange = {  }
                                    //onBlur={ () => (userData.user.razonSocial === '' ? setState({field:'errorRazonSocial',value:true}) : setState({field:'errorRazonSocial',value:false}) ) }
                                    //error = {errorRazonSocial}
                                    //helperText={errorRazonSocial && 'Ingrese la razon social de la empresa'}
                                />
                            </Grid>

                            {/**Nombre Comercial */}
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    id="nombreComercial"
                                    name='nombreComercial'
                                    label="Nombre Comercial"
                                    placeholder='Nombre Comercial'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <StorefrontIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value = {nombreComercial}
                                    onChange = { (event) => setNombreComercial(event.target.value) }
                                    onBlur={ () => (nombreComercial === '' ? setErrorNombreComercial(true) : setErrorNombreComercial(false) ) }
                                    error = {errorNombreComercial}
                                    helperText={errorNombreComercial && 'Ingrese el nombre comercial de la empresa'}
                                />
                            </Grid>

                            {/**RIF */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="rif"
                                    name='rif'
                                    label="RIF"
                                    placeholder='RIF'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <ArticleIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value = {userData.user.documento}
                                    //onChange = { onChange }
                                    //onBlur={ () => (rif === '' ? setState({field:'errorRif',value:true}) : setState({field:'errorRif',value:false}) ) }
                                    //error = {errorRif}
                                    //helperText={errorRif && 'Ingrese el RIF de la empresa'}
                                />
                            </Grid>

                            {/**Categoria 
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        select
                                        disabled={!isEditing}
                                        id="categoria"
                                        name='categoria'
                                        label="Categoria"
                                        placeholder='Seleccione una categoria'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <CorporateFareIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {categoria}
                                        onChange = { (value) => setCategoria(value) }
                                        onBlur={ () => (categoria === '' ? setErrorCategoria(true) : setErrorCategoria(false) ) }
                                        error = {errorCategoria}
                                        helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                    >
                                        <MenuItem value=''>Seleccione una Categoria</MenuItem>
                                        {
                                            categorias.length !== 0
                                            ?
                                            categorias.map(item =>
                                                <MenuItem value={item.idcategoria}>{item.nombre}</MenuItem>
                                            )
                                            :null
                                            
                                        }
                                    </TextField>
                                </FormControl>   
                            </Grid>*/}

                            {/**Descripcion */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    disabled={isEditing}
                                    id="descripcion"
                                    name='descripcion'
                                    label="Descripcion"
                                    placeholder='Descripcion'
                                    variant="outlined"
                                    multiline
                                    rows={12}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <ArticleIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value = {descripcion}
                                    onChange = { (event) => setDescripcion(event.target.value) }
                                    onBlur={ () => (descripcion === '' ? setErrorDescripcion(true) : setErrorDescripcion(false) ) }
                                    error = {errorDescripcion}
                                    helperText={errorDescripcion && 'Ingrese la descripcion de su empresa'}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setError(false);
                    setOpenAlert(false);
                }}
                autoHideDuration={6000}
            >
                <Alert severity={error === true ? 'error' : 'success'}>
                    <AlertTitle>
                        {
                            error === true
                            ? 'Error'
                            : 'Operacion Exitosa'
                        }
                    </AlertTitle>
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}