import { Grid, Stack, TableCell, TableRow, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { NumericFormat } from "react-number-format";


export default function TransactionsDataTable(props)
{
    const columns = [
        {
            name : 'fecha_creacion',
            label : 'Fecha',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "150px" }}),
                filter: false
            }
        },
        {
            name : '',
            label : 'Concecionario',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "250px"}}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}>{props.transactions[dataIndex].evento__pos.comercio.nombre_comercial}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : '',
            label : 'Tipo de Pago',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "150px"}}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}>{props.transactions[dataIndex].forma_pago}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'operacion',
            label : 'Tipo de Operacion',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "150px"}}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}>
                                {
                                    props.transactions[dataIndex].tipo === 'S'
                                    ? 'Consumo'
                                    : 'Recarga'
                                }
                            </Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'referencia',
            label : 'Referencia',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "160px"}}),
                filter: false
            }
        },
        {
            name : 'monto',
            label : 'Monto',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <NumericFormat
                                value={props.transactions[dataIndex].monto}
                                decimalScale={2}
                                thousandSeparator=' '
                                displayType="text"
                                renderText={(value)=><Typography style={{textAlign:'right'}}>{value}</Typography>}
                            />
                            
                        </div>
                    );
                },
                filter: false
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        responsive: 'standard',
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex, expandedRows) => {
            if (dataIndex === 3 || dataIndex === 4) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0)
            return false;
            return true;
        },
        rowsExpanded: [],
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            console.log(props.transactions[rowMeta.dataIndex])
            return (
            <TableRow sx={{bgcolor:'cancel.light'}}>
                <TableCell colSpan={colSpan}>
                    <Grid container spacing={3}>
                        <Grid item lg={6}>
                            <Typography sx={{fontWeight:'bold'}} gutterBottom>Dato del cliente</Typography>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Nombre: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].cliente.nombre} {props.transactions[rowMeta.dataIndex].cliente.apellido}</Typography>
                            </Stack>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Cédula: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].cliente.documento}</Typography>
                            </Stack>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Teléfono: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].cliente.telefono}</Typography>
                            </Stack>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Correo: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].cliente.correo}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item lg={6}>
                            <Typography sx={{fontWeight:'bold'}} gutterBottom>Datos de la transacción</Typography>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Número: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].transaccion_id}</Typography>
                            </Stack>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Referencia: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].referencia}</Typography>
                            </Stack>
                            <Stack flexDirection='row'>
                                <Typography variant="subtitle2" sx={{fontWeight:'bold'}}>- Observaciones: &nbsp;</Typography>
                                <Typography variant='body2'>{props.transactions[rowMeta.dataIndex].observacion}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
            console.log(curExpanded, allExpanded, rowsExpanded),
    };

    return(
        props.transactions.lenght !== 0 &&
        <MUIDataTable
            title='Listado de transacciones'
            columns={columns}
            data={props.transactions}
            options={options}
        />
    )
}