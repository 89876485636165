import { useEffect, useReducer } from "react";
import { Box, Breadcrumbs, Button, CircularProgress, Container, Grid, Link, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { GetComercios } from "../../../controllers/admin/ComerciosController";

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from 'moment';
import { search } from "../../../controllers/TransactionsController";
import TransactionsDataTable from "./table";
import CardInfo from "../../../components/cardInfo";
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { NumericFormat } from "react-number-format";
import { useContext } from "react";
import UserContext from "../../../navigation/context/userContext";

const initialState = {
    comercios: [],
    
    comercio_id:'',
    cliente_id:'',
    fecha_inicio:'',
    fecha_fin:'',
    inicio: null,
    fin: null,

    transacciones: [],
    cantidadTransacciones:0,
    consumo:[],
    recargas:[],
    retiros:[],
    isLoading:false
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function TransactionsClient()
{
    const {userData,setUserData} = useContext(UserContext);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        comercios, comercio_id, cliente_id, fecha_inicio, fecha_fin, isLoading, inicio, fin,
        transacciones, consumo, recargas, retiros, cantidadTransacciones
    } = state;

    useEffect(()=>{
        
        getComercios();
        getTransactions();
        setState({field:'cliente_id',value:userData.user.documento.slice(1)});
       
        console.log('USER_CONTEXT ',userData)
    },[]);


    const getComercios = async () => {
        let response = await GetComercios();
        if(response.success === true)
        {
            setState({field:'comercios',value:response.data.comercios});
        }
    }


    const getTransactions = async () => {
        setState({field:'isLoading',value:true});
        let documento = userData.user.documento.slice(1,10);
        let response = await search(comercio_id,documento,fecha_inicio,fecha_fin);
        if(response.success === true)
        {
            setState({field:'transacciones',value:response.data.transacciones});
            setState({field:'cantidadTransacciones',value:response.data.CantidadRegistro});
            setState({field:'recargas',value:response.data.Recargas});
            setState({field:'consumo',value:response.data.Consumo});
            setState({field:'retiros',value:response.data.Retiro});
        }
        setState({field:'isLoading',value:false});

        console.log('Total de Recargas => ',recargas)
    }


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleInicio = (newValue) => {
        setState({field:'inicio',value:newValue});
        setState({field:'fecha_inicio',value:moment(newValue).format('YYYY/MM/DD')});
    }

    const handleFin = (newValue) => {
        setState({field:'fin',value:newValue});
        setState({field:'fecha_fin',value:moment(newValue).format('YYYY/MM/DD')});
    }


    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        href="/transacciones"
                        aria-current="page"
                    >
                        Transacciones
                    </Link>
                </Breadcrumbs>
            </div>
            <Typography variant='h4' style={{textAlign:'center'}}>Transacciones</Typography>

            <Box sx={{mt:5,mb:5}}>
                <Typography sx={{mb:3}}>Filtros</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <TextField
                            fullWidth
                            select
                            label="Concecionario"
                            id="concecionario"
                            name='comercio_id'
                            value={comercio_id}
                            onChange={onChange}
                            >
                                <MenuItem >
                                    Seleccione un concecionario
                                </MenuItem>
                                {
                                    comercios.lenght !== 0 &&
                                    comercios.map((item)=>
                                        <MenuItem value={item.uuid}>{item.apellido}</MenuItem>
                                    )
                                }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <TextField
                            fullWidth
                            disabled
                            label="Cedula"
                            id="cliente_id"
                            name='cliente_id'
                            value={cliente_id}
                            onChange={onChange}
                        />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                            <MobileDatePicker
                                label="Fecha de Inicio"
                                inputFormat="YYYY/MM/DD"
                                id='inicio'
                                name='inicio'
                                value={inicio}
                                onChange={(newValue) => handleInicio(newValue)}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                            <MobileDatePicker
                                label="Fecha de Inicio"
                                inputFormat="YYYY/MM/DD"
                                id='fin'
                                name='fin'
                                value={fin}
                                onChange={(newValue) => handleFin(newValue)}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Button
                            fullWidth
                            style={{height:'100%'}}
                            variant='contained'
                            onClick={()=>getTransactions()}
                        >
                            {
                                isLoading === true
                                ?<CircularProgress style={{color:'#FFF'}}/>
                                :'Buscar'
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3} sx={{mb:5}}>
                <CardInfo
                    icon={<ReceiptIcon/>}
                    title='Total de transacciones'
                    value={cantidadTransacciones}
                />
                <CardInfo
                    icon={<TrendingUpIcon/>}
                    title='Total de Recargas'
                    value={
                        <NumericFormat
                            value={recargas.total}
                            decimalScale={2}
                            thousandSeparator=' '
                            displayType="text"
                            renderText={(value)=><span>{value} <span style={{fontSize:12}}>usd</span> / {recargas.cantidad} <span style={{fontSize:12}}>tx</span></span>}
                        />
                    }
                />
                <CardInfo
                    icon={<AirlineStopsIcon/>}
                    title='Total de Consumo'
                    value={
                        <NumericFormat
                            value={consumo.total}
                            decimalScale={2}
                            thousandSeparator=' '
                            displayType="text"
                            renderText={(value)=><span>{value} <span style={{fontSize:12}}>usd</span> / {consumo.cantidad} <span style={{fontSize:12}}>tx</span></span>}
                        />
                    }
                />
                <CardInfo
                    icon={<TrendingDownIcon/>}
                    title='Total de Retiros'
                    value={
                        <NumericFormat
                            value={retiros.total}
                            decimalScale={2}
                            thousandSeparator=' '
                            displayType="text"
                            renderText={(value)=><span>{value} <span style={{fontSize:12}}>usd</span> / {retiros.cantidad} <span style={{fontSize:12}}>tx</span></span>}
                        />
                    }
                />
            </Grid>

            <Grid container spacing={3}>
                <Grid item>
                {
                    transacciones.length !== 0 &&
                    <TransactionsDataTable transactions={transacciones}/>
                }
                </Grid>
            </Grid>
        </Container>
    )
}