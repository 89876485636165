import React from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';





export default function MainClientListItems(){

    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/transacciones') } >
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Transacciones" />
            </ListItem>
        </div>
    )    
}
    