import React, { useContext, useReducer } from 'react';
import { Breadcrumbs, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, Slide, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BackButton from '../../../../components/backButton';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { AddCategoria, UpdateCategoria } from '../../../../controllers/pos_offline/CategoriasController';
import UserContext from '../../../../navigation/context/userContext';
import { useEffect } from 'react';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    categoria_id:'',
    nombre : '',        errorNombre : false,
    descripcion : '',   errorDescripcion : false,
    estatus:'',         errorEstatus: false,
    imagen: [],         errorImagen: false,
    rutaImagen: '',
      
    isLoading : false,
    showDialog : false,
    result : false, message : '', title : '',
}

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function CategoriasEmpresaActualizar()
{
    const { userData, setUserData } = useContext(UserContext);
    const [state,setState] = useReducer(reducer,initialState);

    const {
        categoria_id,
        nombre,errorNombre,
        descripcion, errorDescripcion,
        estatus, errorEstatus,
        imagen, errorImagen, rutaImagen,
        isLoading, result,
        showDialog, message, title
    } = state

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    useEffect(() => {
        getCategoria();
    },[]);


    const getCategoria = async () => {
        const categoria = await JSON.parse(localStorage.getItem('ATE_COMMERCE_CATEGORY'));
        setState({field:'categoria_id',value:categoria.uuid});
        setState({field:'nombre',value:categoria.nombre});
        setState({field:'estatus',value:categoria.estatus});
        setState({field:'descripcion',value:categoria.descripcion});
    }


    const handleInputChange = (event) =>
    {
        const target = event.target;
        //const value = target.name === 'isGoing' ? target.checked : target.value;
        const value = target.name === 'isCheck' ? target.checked : target.value;
        const name = target.name;

        setState({field:name,value:value})
    }

    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }
        
        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }

        return ban;
    }

    const handleSubmit = async () => {
        
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let response = await UpdateCategoria(categoria_id,nombre,estatus);
            if(response.success === true)
            {
                setState({field:'title',value:'Operacion Exitosa!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:true});
            }
            else
            {
                setState({field:'title',value:'Error!'});
                setState({field:'message',value:response.message});
                setState({field:'showDialog',value:true});
                setState({field:'isLoading',value:false});
                setState({field:'result',value:false});
            }
        }
    }

    const handleResetForm = () => {
        setState({field:'nombre',value:''});
        setState({field:'descripcion',value:''});
        setState({field:'estatus',value:''});
        setState({field:'image',value:[]});
        setState({field:'rutaImagen',value:''});
        setState({field:'result',value:false});
    }


    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        aria-current="page"
                        onClick={()=>redireccionar('/products')}
                    >
                        Productos
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        aria-current="page"
                        onClick={()=>redireccionar('/products/categories')}
                    >
                        Categorías
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Actualizar
                    </Link>
                </Breadcrumbs>
                <BackButton route='/products/categories'/>
            </div>

            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Actualizar Categoría
            </Typography>

            <Grid container spacing={3} style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <Grid item lg={5}>
                    <Paper elevation={4}>
                        <Box
                            component='form'
                            sx={{
                                '& .MuiFormControl-root': { mt: 1 },
                                '& .MuiLoadingButton-root': { mt: 1 }
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                label="Nombre"
                                id='nombre'
                                name='nombre'
                                value={nombre}
                                onChange={handleInputChange}
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del evento'}
                            />
                            {/**
                            <TextField
                                required
                                fullWidth
                                label="Descripcion"
                                id='descripcion'
                                name='descripcion'
                                multiline
                                rows={4}
                                value={descripcion}
                                onChange={handleInputChange}
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripcion del evento'}
                            /> */}
                           
                            <FormControl fullWidth>
                                <InputLabel id="estatus">Estatus</InputLabel>
                                <Select
                                    labelId="estatus"
                                    id="estatus"
                                    name="estatus"
                                    value={estatus}
                                    label="Estatus"
                                    onChange={handleInputChange}
                                    onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                                    error = {errorEstatus}
                                >
                                    <MenuItem value='1'>Activo</MenuItem>
                                    <MenuItem value='0'>Inactivo</MenuItem>
                                </Select>
                                {
                                    errorEstatus && <FormHelperText style={{color:'#FA0000'}}>Selecciones el estatus</FormHelperText>
                                }
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                variant='contained'
                                loading={isLoading}
                                onClick={handleSubmit}
                            >
                                Actualizar Categoría
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={showDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                //onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                        {message} <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        result === true
                        ?
                        <Button onClick={() => history.replace('/products/categories') } color="primary" style={{color:'#000'}}>
                            Aceptar
                        </Button>
                        :
                        <Button onClick={()=>setState({field:'showDialog',value:false})} color="primary" style={{color:'#000'}}>
                            Aceptar
                        </Button>
                    }
                    
                </DialogActions>
            </Dialog>

        </Container>
    )
}