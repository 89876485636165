import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumbs, Container, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Terminal from './terminal';
import { GetPOSByID, GetTransactions } from '../../../controllers/pos_offline/PosController';
import UserContext from '../../../navigation/context/userContext';
import POSDataTable from './table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useHistory } from 'react-router-dom';


export default function POS()
{
    const {userData,setUserData} = useContext(UserContext);
    const [terminales,setTerminales] = useState([]);
    const [transacciones,setTransacciones] = useState([]);

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    useEffect(()=>{
        getTerminales();
        getTransacciones();
    },[])

    const getTerminales = async() => {
        let response = await GetPOSByID(userData.user.documento);
        setTerminales(response.data);
        console.log(response);
    }

    const getTransacciones = async() => {
        let response = await GetTransactions(userData.user.documento);
        setTransacciones(response.data);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/pos')}
                        aria-current="page"
                    >
                        Dispositivos
                    </Link>
                </Breadcrumbs>
                <IconButton onClick={()=>history.push('/')}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginBottom:30}}>
                Dispositivos
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper elevation={4}>
                                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <Typography variant="h6" style={{marginBottom:15}}>Listado de dispositivos</Typography>
                                    <IconButton color='primary' onClick={()=>redireccionar('/pos/agregar')}>
                                        <AddCircleIcon/>
                                    </IconButton>
                                </div>
                                
                                <List>
                                    {
                                        terminales.length > 0
                                        ?<Terminal terminals={terminales}/>
                                        :'No hay dispositivos '
                                    }
                                    
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'secondary.main',color:'secondary.contrastText'}}>
                                <Typography variant='h6'>
                                    Cantidad de POS
                                </Typography>
                                <Typography variant='h4'>
                                    -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'secondary.main',color:'secondary.contrastText'}}>
                                <Typography variant='h6'>
                                    Cantidad de operaciones
                                </Typography>
                                <Typography variant='h4'>
                                    -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'primary.main',color:'primary.contrastText'}}>
                                <Typography variant='h6'>
                                    Ingresos
                                </Typography>
                                <Typography variant='h4'>
                                    $ -
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4} sx={{bgcolor:'primary.main',color:'primary.contrastText'}}>
                                <Typography variant='h6'>
                                    Egresos
                                </Typography>
                                <Typography variant='h4'>
                                    $ -
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <br/><br/>

            <Grid Container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <POSDataTable data={transacciones}/>
                </Grid>
            </Grid>
        </Container>
    )
}