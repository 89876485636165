import ROUTES from '../../navigation/routes';
import { GET, POST } from './ConsumeApi';


export async function get_services(comercio_id)
{
    let response = await GET(ROUTES.POS_OFFLINE.SERIVICES.GetActiveServices+comercio_id);
    return response;
}

export async function suscribe_service(comercio_id,servicio_id)
{
    const params = [
        {name:'comercio_id',value:comercio_id},
        {name:'servicio_id',value:servicio_id}
    ];

    console.log('params ===> ',params);

    let response = await POST(ROUTES.POS_OFFLINE.SERIVICES.SuscribeService,params);
    return response;
}

export async function unsuscribe_service(afiliado)
{
    let response = await GET(ROUTES.POS_OFFLINE.SERIVICES.UnsuscribeService+afiliado);
    return response;
}