import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, CardActionArea, CircularProgress, Container, Grid, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import BackButton from '../../../components/backButton';
import ProductsDataTable from './table';
import { GetCategorias } from '../../../controllers/pos_offline/CategoriasController';
import UserContext from '../../../navigation/context/userContext';
import ROUTES from '../../../navigation/routes';

export default function Products()
{
    const {userData,setUserData} = useContext(UserContext);
    const [categorias,setCategorias] = useState([]);
    const [showProgress,setShowProgress] = useState(false);

    let history = useHistory();

    useEffect(()=>{
        getCategorias();
    },[]);

    const redireccionar = (route) => {
        history.replace(route)
    }

    const getCategorias = async () => {
        setShowProgress(true);
        let response = await GetCategorias(userData.user.documento);
        
        if(response.success === true)
        {
            setCategorias(response.data);
            setShowProgress(false);
        }
    }

    const setCategoria = async (item) => {
        await localStorage.setItem('ATE_CATEGORIA_PRODUCTOS',JSON.stringify(item));
        history.push('/productos/categoria');
    }


    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Productos
                    </Link>
                </Breadcrumbs>
                <BackButton route='/'/>
            </div>

            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Productos
            </Typography>

            <Grid container spasing={3}>
                <Grid item lg={3}>
                    <Grid container spacing={3} style={{display:'flex',flexDirection:'row',marginTop:5}}>
                        <Grid item>
                            <Button variant='contained' onClick={()=> redireccionar('/products/categories')}>
                                Categorías
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={9}>
                    <Grid container spacing={3} style={{display:'flex',flexDirection:'row-reverse',marginTop:5}}>
                        <Grid item lg={3}>
                            <Button variant='contained' onClick={()=> redireccionar('/products/add')}>Agregar Producto</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br/><br/>
            {/**
            <Grid container maxWidth='lg' spacing={3}>
                {
                    showProgress === true
                    ?
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',marginTop:20}}>
                        <CircularProgress/>
                    </div>
                    :
                    categorias.length !== 0 &&
                    categorias.map((item)=>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Card sx={{borderRadius:2}}>
                                <CardActionArea onClick={()=>setCategoria(item)}>
                                    <Box
                                        sx={{
                                            height:200,
                                            borderRadius:2,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: 'background.default',
                                            color: 'text.primary',
                                            p: 3,
                                            //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                            //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                            backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + ROUTES.POS_OFFLINE.IMG+'Categoria/'+item.ruta_img + ")",
                                            backgroundPosition:'center',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover',
                                        }}
                                    >
                                        <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                            
                                            <Typography component='h5' variant='h5' color='primary.contrastText'>{item.nombre}</Typography>
                                            
                                        </div>
                                    </Box>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>
            <br/><br/><br/><br/> */}
            <ProductsDataTable/>
        </Container>
    )
}