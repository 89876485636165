import React from 'react';
import { Breadcrumbs, Container, IconButton, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

export default function POSAdd()
{
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }
    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
                <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                    <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        onClick={()=>redireccionar('/pos')}
                        aria-current="page"
                    >
                        Dispositivos
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        sx={{cursor:'pointer'}}
                        aria-current="page"
                    >
                        Agregar
                    </Link>
                </Breadcrumbs>
                <IconButton onClick={()=>history.push('/pos')}>
                    <ArrowBackIcon/>
                </IconButton>
            </div>
            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Agregar Dispositivo                            
            </Typography>
        </Container>
    )
}