import { Switch } from "react-router-dom";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import Dashboard from "../views/company";
import POS from "../views/company/pos";
import Profile from "../views/company/profile";
import Reviews from "../views/company/reviews";
import AppRoute from "./AppRoute";

import AuthLayout from "../layouts/authLayout";
import Review from "../views/client/review";
import ChangePassword from "../views/seguridad";
import Products from "../views/company/products";
import Services from "../views/company/services";
import CategoriasEmpresa from "../views/company/products/categories";
import CategoriasEmpresaAgregar from "../views/company/products/categories/add";
import ProductsAdd from "../views/company/products/add";
import ProductsUpdate from "../views/company/products/update";
import CategoriasEmpresaActualizar from "../views/company/products/categories/update";
import POSAdd from "../views/company/pos/add";
import TransactionsCompany from "../views/company/transactions";

export default function CompanyNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={CompanyLayout} component={Dashboard} />
            <AppRoute exact path='/company' layout={CompanyLayout} component={Profile} />
            <AppRoute exact path='/seguridad' layout={CompanyLayout} component={ChangePassword} />
            <AppRoute exact path='/pos' layout={CompanyLayout} component={POS} />
            <AppRoute exact path='/pos/agregar' layout={CompanyLayout} component={POSAdd} />
            <AppRoute exact path='/eventos' layout={CompanyLayout} component={POS} />
            <AppRoute path='/events/details' layout={CompanyLayout} component={POS} />

            {/**Servicios - Productos*/}
            <AppRoute exact path='/services' layout={CompanyLayout} component={Services}/>
            <AppRoute exact path='/products' layout={CompanyLayout} component={Products}/>
            <AppRoute exact path='/products/add' layout={CompanyLayout} component={ProductsAdd}/>
            <AppRoute exact path='/products/update' layout={CompanyLayout} component={ProductsUpdate}/>
            <AppRoute exact path='/products/categories' layout={CompanyLayout} component={CategoriasEmpresa}/>
            <AppRoute path='/products/categories/add' layout={CompanyLayout} component={CategoriasEmpresaAgregar}/>
            <AppRoute path='/products/categories/update' layout={CompanyLayout} component={CategoriasEmpresaActualizar}/>

            <AppRoute exact path='/transacciones' layout={CompanyLayout} component={TransactionsCompany}/>
            
        </Switch>
    )
}