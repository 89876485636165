import { useHistory } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Copyright from "../../components/copyRight";

import logo from '../../assets/img/logo.jpg';

export default function SignUp()
{

    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }
    

    return(
        <Box
            sx={{
                height:'87vh',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                bgcolor:'background.primary'
            }}
        >
            <img src={logo} style={{width:100,marginBottom:40}} alt='QUD Logo'/>
            <Typography style={{textAlign:'center',marginBottom:50}}>Por favor seleccione el tipo de usuario que desea registrar</Typography>
            
            <Grid container spacing={3} style={{display:'flex', flexDirection:'column',width:'100%',justifyContent:'center',alignItems:'center'}}>    
                <Stack flexDirection='row' width={'100%'} justifyContent='center' >
                <Grid item lg={2} style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Button onClick={ () => redirect('/signup/person')}>
                        <Paper style={{padding:30}}>
                            <div>
                                <PersonIcon color="primary" fontSize="large"/>
                                <Typography color='primary'>
                                    Cliente
                                </Typography>
                            </div>
                        </Paper>
                    </Button>
                </Grid>

                {/**
                <Grid item lg={2} style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                    <Button onClick={ () => redirect('/signup/company')}>
                        <Paper style={{padding:30}}>
                            <div>
                                <AddBusinessIcon color="primary" fontSize="large"/>
                                <Typography color='primary'>
                                    Empresa
                                </Typography>
                            </div>
                        </Paper>
                    </Button>
                </Grid>
                */}
                </Stack>
            </Grid>
            
        </Box>
    )
}