import { Switch } from "react-router-dom";
import AdminLayout from "../layouts/DashboardAdmin/adminLayout";
import DashboardAdmin from "../views/admin";
import Business from "../views/admin/business";
import BusinessAdd from "../views/admin/business/add";
import BusinessDetails from "../views/admin/business/details";

import Transactions from "../views/admin/transactions";
import Profile from "../views/company/profile";
import AppRoute from "./AppRoute";





export default function AdminNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AdminLayout} component={DashboardAdmin} />
            

            {/**Comercios */}
            <AppRoute exact path='/comercios' layout={AdminLayout} component={Business} />
            <AppRoute exact path='/comercios/agregar' layout={AdminLayout} component={BusinessAdd} />
            <AppRoute exact path='/comercios/:qrcode' layout={AdminLayout} component={BusinessDetails} />

            {/**Transacciones */}
            <AppRoute exact path='/transacciones' layout={AdminLayout} component={Transactions} />
        </Switch>
    )
}