import React, { useContext, useEffect, useState } from 'react';
import { Chip, IconButton, Typography } from '@mui/material';
import MUIDataTable from "mui-datatables";


import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import { useHistory } from 'react-router-dom';
import ROUTES from '../../../navigation/routes';
import UserContext from '../../../navigation/context/userContext';
import { ChangeStatus, GetProductos } from '../../../controllers/pos_offline/ProductosController';


export default function ProductsDataTable()
{
    const { userData, setUserData } = useContext(UserContext);
    const [products,setProducts] = useState([]);

    const history = useHistory();
    useEffect(()=>{
        getProducts();
    },[]);

    const getProducts = async () => {
        let response = await GetProductos(userData.user.documento);
        if(response.success === true)
        {
            setProducts(response.data);
        }
    }

    const changeStatus = async (idProducto) => {
        
        let response = await ChangeStatus(idProducto);
        
        if(response.success === true)
        {
            getProducts();
        }
    }

    const goToUpdate = async (product) => {
        localStorage.setItem('ATE_COMMERCE_PRODUCT',JSON.stringify(product));
        history.push('/products/update');
    }

    const columns = [
        {
            name : '',
            label : 'Imagen',
            options :
            {
                setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "60px" }}),
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                           <img style={{width:100,height:100,borderRadius:10}} src={ROUTES.POS_OFFLINE.IMG + 'Productos/' + products[dataIndex].ruta_img} alt={products[dataIndex].nombre}/>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Producto',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div>
                            <Typography style={{fontSize:14}}><strong >{products[dataIndex].nombre}</strong> (Categoría)</Typography>
                            <Typography style={{fontSize:12}}>{products[dataIndex].descripcion}</Typography>
                        </div>
                    );
                },
                filter: false
            }
        },
        {
            name : 'nombre',
            label : 'Producto',
            options :
            {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name : 'comercio_categoria_id',
            label : 'Categoria',
            options :
            {
                display:false,
                filter: true,
                sort: false
            }
        },
        {
            name:'precio',
            label:'Precio $',
            options :
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography style={{textAlign:'right'}}>{products[dataIndex].precio}</Typography>
                        
                    );
                },
                filter: false
            }
        },
        {
            name : 'estatus',
            label : 'Estatus',
            options : 
            {
                customBodyRenderLite : (dataIndex) => (
                    products[dataIndex].estatus === 1
                    ? <Chip label='Activo' style={{background:'#13ad41',color:'#FFF'}} />
                    : <Chip label='Inactivo' color='error' />
                )            
            },
        },
        {
            name : '',
            label : 'Acciones',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            {
                                products[dataIndex].estatus === 1
                                ?
                                <IconButton onClick={()=>changeStatus(products[dataIndex].uuid)}>
                                    <DeleteIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>changeStatus(products[dataIndex].uuid)}>
                                    <PlayArrowIcon/>
                                </IconButton>
                            }                            
                            <IconButton onClick={()=>goToUpdate(products[dataIndex])}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                    );
                },
                filter: false,
                print : false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        products !== []
        ?
        <MUIDataTable
            title='Listado de Productos'
            columns={columns}
            data={products}
            options={options}
        />
        :null
    )

}