import { Switch } from "react-router-dom";
import ClientLayout from "../layouts/DashboardCliente/clientLayout";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import Dashboard from "../views/client";
import GuacoZone from "../views/client/events/guaco/zone";
import EventTicketing from "../views/client/events/ticketing";
import Profile from "../views/client/profile";
import Review from "../views/client/review";
import TransactionsClient from "../views/client/transactions";
import AppRoute from "./AppRoute";





export default function ClientNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={ClientLayout} component={Dashboard} />
            
            {/**Transacciones */}
            <AppRoute exact path='/transacciones' layout={ClientLayout} component={TransactionsClient} />
        </Switch>
    )
}