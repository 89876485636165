import React, { useEffect, useState, useContext } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, CardMedia, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, Paper, Slide, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { get_services, suscribe_service, unsuscribe_service } from '../../../controllers/pos_offline/services';
import ROUTES from '../../../navigation/routes';
import UserContext from '../../../navigation/context/userContext';
import { LoadingButton } from '@mui/lab';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Services()
{
    const { userData, setUserData } = useContext(UserContext);
    const [servicios,setServicios] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [showDialog,setShowDialog] = useState(false);
    const [message,setMessage] = useState('');
    const [title,setTitle] = useState('');
    const [result,setResult] = useState(false);


    //showDialog, message, title

    useEffect(()=>{
        GetServicios();
    },[]);

    const GetServicios = async () => {
        let response = await get_services(userData.user.documento);
        console.log(response)
        setServicios(response.data);
    }

    let history = useHistory();

    const redireccionar = (route) => {
        history.replace(route)
    }

    const Suscribirse = async (servicio_id) => {
        setIsLoading(true)
        let response = await suscribe_service(userData.user.documento,servicio_id);
        if(response.success == true)
        {
            await setTitle('Operacion Exitosa');
            await setMessage(response.message);
            await setShowDialog(true);
            await setIsLoading(false);
            await setResult(true);
        }
        else
        {
            await setTitle('Error!');
            await setMessage(response.message);
            await setShowDialog(true);
            await setIsLoading(false);
            await setResult(false);
        }
    }

    const DarseDeBaja = async (afiliado) => {
        setIsLoading(true);
        let response = await unsuscribe_service(afiliado);
        if(response.success == true)
        {
            await setTitle('Operacion Exitosa');
            await setMessage(response.message);
            await setShowDialog(true);
            await setIsLoading(false);
            await setResult(true);
        }
        else
        {
            await setTitle('Error!');
            await setMessage(response.message);
            await setShowDialog(true);
            await setIsLoading(false);
            await setResult(false);
        }
        console.log(response);
    }

    const handleClose = async () => {
        GetServicios();
        await setTitle('');
        await setMessage('');
        await setShowDialog(false);
        await setIsLoading(false);
        await setResult(false);
    }

    return(
        <Container>
            <div style={{display:'flex',flexDirection:'row-reverse'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" 
                        onClick={()=>redireccionar('/')}
                    >
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="text.primary"
                        aria-current="page"
                    >
                        Servicios
                    </Link>
                </Breadcrumbs>
            </div>

            <Typography variant='h5' style={{textAlign:'center',marginBottom:20}}>
                Servicios
            </Typography>
            <Typography variant='h6' style={{marginBottom:30}}>
                En Technological Sharks estamos convencidos de que todo mundo requiere de las herramientas que la tecnología nos ofrece, por esta razón ofrecemos herramientas digitales tales como:
            </Typography>
            
            <Grid container spacing={3}>
                {
                    servicios.length !== 0 &&
                    servicios.map((item) =>
                        <Grid item lg={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3}>
                                    <Grid item lg={4}>
                                        <img style={{width:'100%',height:'auto'}} src={ROUTES.POS_OFFLINE.IMG+'Servicios/'+item.ruta_img}/>
                                    </Grid>
                                    <Grid item lg={8}>
                                        <Grid container style={{display:'flex',flexDirection:'space-between'}}>
                                            <Grid item lg={8}>
                                                <Typography variant='h5'>{item.nombre}</Typography>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Typography style={{textAlign:'right'}} variant='h5'>$ {item.precio}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='body1'>{item.descripcion}</Typography>
                                        {
                                            item.afiliado !== null
                                            ?<Typography variant='caption'>Fecha de corte {item.corte} de cada mes</Typography>
                                            :null
                                        }
                                        <Grid item lg={12} style={{marginTop:10,display:'flex',justifyContent:'right'}}>
                                            {
                                                item.afiliado === null
                                                ?
                                                <LoadingButton
                                                    variant='contained'
                                                    loading={isLoading}
                                                    onClick={()=>Suscribirse(item.uuid)}
                                                >
                                                    Suscribirse
                                                </LoadingButton>
                                                :
                                                <LoadingButton
                                                    variant='contained'
                                                    color='error'
                                                    loading={isLoading}
                                                    onClick={()=>DarseDeBaja(item.afiliado)}
                                                >
                                                    Darse de baja
                                                </LoadingButton>
                                                
                                            }
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>


            <Dialog
                open={showDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                //onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    {message} <br/>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()} color="primary" style={{color:'#000'}}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}