import React, { useContext } from 'react';
import { Avatar, Card, CardActionArea, CardContent, Container, Grid, Typography } from '@mui/material';
import UserContext from '../../navigation/context/userContext';
import { useHistory } from 'react-router-dom';

import ReceiptIcon from '@mui/icons-material/Receipt';
import StoreIcon from '@mui/icons-material/Store';

export default function DashboardAdmin()
{
    const {userData,setUserData} = useContext(UserContext);

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    return(
        <Container>
            <Typography variant='h5' style={{textAlign:'center'}}>
                Bienvenido {userData.user.name}
            </Typography>
            <Typography gutterBottom>Accesos Directos</Typography>
            <Grid container spacing={3}>
                <Grid item lg={3}>
                    <Card sx={{p:0}}>
                        <CardActionArea onClick={()=>redireccionar('/comercios')}>
                            <CardContent sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <Avatar sx={{bgcolor:'primary.main', width: 56, height: 56}}>
                                    <StoreIcon sx={{ fontSize: 35 }}/>
                                </Avatar>
                                <Typography component='h6' variant='h6' style={{textAlign:'center',marginTop:10,fontWeight:'bold'}}>
                                    Concecionarios
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={3}>
                    <Card sx={{p:0}}>
                        <CardActionArea onClick={()=>redireccionar('/transacciones')}>
                            <CardContent sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <Avatar sx={{bgcolor:'primary.main', width: 56, height: 56}}>
                                    <ReceiptIcon sx={{ fontSize: 35 }}/>
                                </Avatar>
                                <Typography component='h6' variant='h6' style={{textAlign:'center',marginTop:10,fontWeight:'bold'}}>
                                    Transacciones
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )

}