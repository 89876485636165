import ROUTES from '../../navigation/routes';
import { GET } from './ConsumeApi';


//GET data from pos
export async function GetPOSByID(documento)
{
    let response = await GET(ROUTES.POS_OFFLINE.GetPosByID+documento);
    return response;
}


//GET TRANSACTIONS FROM POS
export async function GetTransactions(documento)
{
    let response = await GET(ROUTES.POS_OFFLINE.GetTransactions+documento+'/7');
    return response;
}