import React from 'react';

import { Typography, Link } from '@mui/material';

function Copyright() {
    return (
        <footer style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',width:'100%'}}>
            {/** 
            <Typography variant="caption" color="text.primaryText" align="center" style={{marginTop:10,marginBottom:10}}>
                Copyright © QUD.LIFE {new Date().getFullYear()}
            </Typography>
            */}
            <Typography variant="caption" color="#212121" style={{textAlign:'center',marginBottom:'-5px'}}>
                Powered By 
            </Typography>
            <Link variant='caption' color="#212121" href="https://technologicalsharks.com/" target='_blank' style={{textDecoration:'none',marginBottom:10}}>
                Technological Sharks
            </Link>{' '}
        </footer>
    );
  }

  export default Copyright;