import { Box } from "@mui/material";
import ThemeMode from "../assets/theme/theme";
import Copyright from "../components/copyRight";

export default function AuthLayout(props)
{
    return(
        <Box>
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    //height: '100vh',
                    overflow: 'auto',
                    minHeight: '90vh'
                  }}
            >
                {props.children}
                <br/><br/>
            
            </Box>
            <Copyright/>
        </Box>
    )
}