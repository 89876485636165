import ROUTES from "../navigation/routes";
import { GET, POST } from "./ConsumeApiController";

/**
 * Funcion para registrar un usuario en ATE
 * @param razonSocial es el nombre legal de la empresa o comercio
 * @param nombreComercial es el nombre comercial de la empresa o comercio (es el que usa en su publicidad)
 * @param documento es el documento identificativo de la empresa, puede ser rif o cedula
 * @param descripcion es la descripcion de la empresa
 * @param categoria es la categoria a la que pertenece la empresa
 * @param nombre es el nombre de la persona encargada de la empresa
 * @param apellido es el apellido de la persona encargada de la empresa
 * @param cedula es el numero de cedula de la persona encargada del negocio
 * @param telefono es el numero de telefono asociado a la empresa, y a traves del cual el encargado se podra comunicar con el soporte tecnico
 * @param direccion es la direccion de la empresa
 * @param lat es la latitud de la ubicacion de la empresa
 * @param lng es la longitud de la ubicacion de la empresa
 * @param email es el correo electronico con el cual se identificara la empresa
 * @param username es el nombre de usuario que usara la empresa
 * @param password es la contrsena de acceso con la cual la empresa podra ingresar al dashboar administrativo
 * 
 */

 export async function signup(razonSocial,nombreComercial,documento,descripcion,email,password)
 {
    
    var params = 
    [
        {name:'razonSocial',value:razonSocial},
        {name:'nombreComercial',value:nombreComercial},
        {name:'documento',value:documento},
        {name:'descripcion',value:descripcion},

        //Datos de acceso
        {name:'email',value:email},
        {name:'password',value:password}
    ];

    var response = await POST(ROUTES.AUTH.CompanySignup,params);
    return response;
}


/**
 * Funcion para registrar un usuario en ATE
 * @param name es el nombre de la persona
 * @param apellido es el apellido de la persona
 * @param documento es el documento identificativo de la persona
 * @param telefono es el numero de telefono asociado a la empresa, y a traves del cual el encargado se podra comunicar con el soporte tecnico
 * @param email es el correo electronico con el cual se identificara la empresa
 * @param username es el nombre de usuario que usara la empresa
 * @param password es la contrsena de acceso con la cual la empresa podra ingresar al dashboar administrativo
 * 
 */
 export async function signup_person(name,apellido,documento,telefono,email,password)
 {
    const params = 
    [
        {name:'name',value:name},
        {name:'apellido',value:apellido},
        {name:'documento',value:documento},
        {name:'telefono',value:telefono},

        //Datos de acceso
        {name:'email',value:email},
        {name:'password',value:password}
    ];

    let response = await POST(ROUTES.AUTH.PersonSignup,params);
    return response;
}


/**
 * Function to Login the user
 * @param email , user email
 * @param password , user password
 */
 export async function login(email,password)
 {
     email = await email.toLowerCase();
     const params = 
    [
        {name:'email',value:email},
        {name:'password',value:password}
    ];
     try
     {
         let response = await POST(ROUTES.AUTH.Login,params);
         return response;
     }
     catch(error)
     {
         console.log(error)
     }
 }



 /**
  * Function to change password
  * @param currentPassword is the current password,
  * @param password is the new password
  */
 export async function changePasswordWithToken(currentPassword,password)
 {
     let params = [
         {name:'currentPassword',value:currentPassword},
         {name:'password',value:password},
     ];

     let response = await POST(ROUTES.AUTH.changePasswordWithToken,params);
     return response;
 }
 




 /**
  * funcion para obtener la lista de categorias activas
  */
 export async function get_Categorias()
 {
    let response = await GET(ROUTES.AUTH.categorias);
    return response
 }