import React, { useContext } from 'react';
import { Avatar, Button, Card, CardActionArea, CardContent, Container, Grid, Paper, Typography } from '@mui/material';

import UserContext from '../../navigation/context/userContext';
import { useHistory } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';

export default function DashboardCliente()
{
    const {userData,setUserData} = useContext(UserContext);
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    return(
        <Container>
            <Typography variant='h6' style={{textAlign:'center',marginBottom:10,marginTop:30}}>
                Bienvenido {userData.user.name}
            </Typography>
            <Typography gutterBottom>Accesos Directos</Typography>
            <Grid container spacing={3}>
                <Grid item lg={3}>
                    <Card sx={{p:0}}>
                        <CardActionArea onClick={()=>redireccionar('/transacciones')}>
                            <CardContent sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <Avatar sx={{bgcolor:'primary.main', width: 56, height: 56}}>
                                    <ReceiptIcon sx={{ fontSize: 35 }}/>
                                </Avatar>
                                <Typography component='h6' variant='h6' style={{textAlign:'center',marginTop:10,fontWeight:'bold'}}>
                                    Transacciones
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}