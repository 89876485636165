import ROUTES from '../../navigation/routes';
import { GET, POST } from './ConsumeApi';



export async function GetCategorias(comercio_id)
{
    let response = await GET(ROUTES.POS_OFFLINE.COMMERCE.CATEGORIES.GetAll+comercio_id);
    return response;
}

export async function GetCategoriasActivas(comercio_id)
{
    let response = await GET(ROUTES.POS_OFFLINE.COMMERCE.CATEGORIES.GetActive+comercio_id);
    return response;
}


export async function AddCategoria(nombre,comercio_id,estatus,imagen)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'comercio_id',value:comercio_id},
        {name:'ruta_img',value:imagen},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(ROUTES.POS_OFFLINE.COMMERCE.CATEGORIES.Add,params);
    return response; 
}

export async function UpdateCategoria(categoria_id,nombre,estatus)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'estatus',value:estatus}
    ];

    let response = await POST(ROUTES.POS_OFFLINE.COMMERCE.CATEGORIES.Update+categoria_id,params);
    return response; 
}

export async function ChangeStatus(categoria_id)
{
    let response = GET(ROUTES.POS_OFFLINE.COMMERCE.CATEGORIES.ChangeStatus+categoria_id);
    return response;
}