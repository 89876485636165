import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, 
        Select, MenuItem, InputAdornment, Button, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert, AlertTitle, FormControlLabel, Checkbox, Link, FormHelperText 
    } from '@mui/material';

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article'; //rif
import ListIcon from '@mui/icons-material/List';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import PersonIcon from '@mui/icons-material/Person';

import logo from '../../assets/img/logo.jpg';
import Copyright from '../../components/copyRight';
import { LoadingButton } from '@mui/lab';
import { get_Categorias, signup } from '../../controllers/AuthController';

import GoogleMapReact from 'google-map-react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { GetCitiesPublic } from '../../controllers/CitiesControllers';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const userReg = /^[a-z0-9_-]{3,20}$/; //"El nombre de usuario solo permite letras, números y los caracteres - y _"

const letrasReg = /[A-Za-z]$/;
const rifReg = /^([0-9]{7,9})$/;
const cedulaReg = /^([0-9]{7,9})$/;
const telefonoReg = /^([0424|0414|0412|0414|0426]{4})\s?-?\s?\d{3}\s?-?\s?\d{4}/; //4129409998

const passwrodReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#.!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,20}$/;

//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    //DATOS EMPRESA
    razonSocial : '',
    nombreComercial : '',
    rifPrefix:'J',
    rif : '',
    descripcion : '',
    categoria : '',
  
    errorRazonSocial : false,
    errorNombreComercial : false,
    errorRif : false, 
    errorDescripcion : false,
    errorCategoria : false,
  
    nombre : '', errorNombre : false,
    apellido : '', errorApellido : false,
    cedulaPrefix:'V',
    cedula : '', errorCedula : false,
    telefono : '',errorTelefono : false,
  
  
    ciudad: '', errorCiudad: false,
    //coordenadas : null, 
    coordenadas:{lat : 10.153135, lng : -64.678742},
    errorCoordenadas : false,
    //center : {lat: 10.1976900, lng: -64.6940700},
    initialCenter : {lat:10.153135,lng:-64.678742},
    center : {lat : 10.153135, lng : -64.678742},
    markerPosition : {lat:10.153135,lng:-64.678742},
    markerPositionInicial : {lat:10.153135,lng:-64.678742},
  
    zoom : 16,
    direccion : '',   errorDireccion : false,
  
    email : '', errorEmail : false,
    user : '', errorUser : false,
    password : '', errorPassword : false,
    passwordConfirm : '', errorPasswordConfirm : false,

    agree:false, errorAgree: false,
  
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  
  

export default function SignUpCompany()
{
    const [state,setState] = useReducer(reducer,initialState);
    const {
            razonSocial, errorRazonSocial,
            nombreComercial, errorNombreComercial,
            rifPrefix, rif, errorRif,
            descripcion, errorDescripcion,
            categoria, errorCategoria,
  
            nombre, errorNombre,
            apellido, errorApellido,
            cedulaPrefix, cedula, errorCedula,
            telefono, errorTelefono,
  
            ciudad, errorCiudad,
            coordenadas, errorCoordenadas, center, zoom,
            direccion, errorDireccion,
  
            email, errorEmail,
            user, errorUser,
            password, errorPassword,
            passwordConfirm, errorPasswordConfirm,
            agree, errorAgree,

            isLoading,
            errorSignIn, errorSignInMsg,
            redirectToVerify,
            registrado
  
    } = state;


    const [categorias,setCategorias] = useState([]);
    const [ciudades,setCiudades] = useState([]);


    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }


    useEffect(()=>{
        getCategorias();
        getCiudades();
    },[])


    const getCiudades = async () => {
        let response = await GetCitiesPublic();
        if(response.success === true)
        {
            setCiudades(response.data.ciudades);
        }
    }

    const getCategorias = async () => {
        let response = await get_Categorias();
        if(response.success === true)
        {
            setCategorias(response.data.categorias);
        }
    }


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleClose = () => {
        setState({field:'errorSignIn',value:false});
    };
    
  
    const formVerification = () => {
        var ban = 0;

        if(razonSocial === '')
        {
            console.log('error en razonSocial')
            setState({field:'errorRazonSocial',value:true});
            ban = 1;
        }

        
        if(nombreComercial === '')
        {
            console.log('error en nombreComercial')
            setState({field:'errorNombreComercial',value:true});
            ban = 1;
        }
            
        if(rif === '' || rifReg.test(rif) === false)
        {
            console.log('error en rif')
            setState({field:'errorRif',value:true});
            ban = 1;
        }
        
        if(categoria === '')
        {
            console.log('error en categoria')
            setState({field:'errorCategoria',value:true});
            ban = 1;
        }

        if(nombre === '')
        {
            console.log('error en nombre')
            setState({field:'errorNombre',value:true});
            ban = 1;
        }
        
        if(apellido === '')
        {
            console.log('error en apellido')
            setState({field:'errorApellido',value:true});
            ban = 1;
        }
            
        if(cedula === '' || cedulaReg.test(cedula) === false)
        {
            console.log('error en cedula')
            setState({field:'errorCedula',value:true});
            ban = 1;
        }
        
        if(telefono === '' || telefonoReg.test(telefono) === false)
        {
            console.log('error en telefono')
            setState({field:'errorTelefono',value:true});
            ban = 1;
        }

        //Direccion y Coordenadas
        if(direccion === '')
        {
            console.log('error en direccion')
            setState({field:'errorDireccion',value:true});
            ban = 1;
        }

        /** 
        if(coordenadas == null)
        {
            setState({field:'errorCoordenadas',value:true});
            ban = 1;
        }*/

        if(email === '' || emailReg.test(email) === false)
        {
            console.log('error en email')
            setState({field:'errorEmail',value:true});
            ban = 1;
        }
        
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(agree !== true)
        {
            setState({field:'errorAgree',value:true});
            ban = 1;
        }

        return ban;
    }

    const companySignUp = async () => {
        
        try
        {
            //setState({field:'isLoading',value:true});
            let _rif = rifPrefix+rif;
            let _cedula = cedulaPrefix+cedula;
    
            let response = await signup(
                                razonSocial,nombreComercial,_rif,descripcion,
                                categoria,nombre,apellido,_cedula,telefono,
                                ciudad,direccion,coordenadas.lat,coordenadas.lng,
                                email,password
    
                            );
            console.log(response)
            if(response.success === true)
            {
                await setState({field:'registrado',value:true});
                await setState({field:'isLoading',value:false});
                setState({field:'redirectToVerify',value:true});
            }
            else
            {
                setState({field:'isLoading',value:false});
                setState({field:'errorSignIn',value:true});
                setState({field:'errorSignInMsg',value:response.message});
            }
        }
        catch(error)
        {
            setState({field:'isLoading',value:false});
            setState({field:'errorSignIn',value:true});
            setState({field:'errorSignInMsg',value:error});
        }
    }

    const onMapClick = (obj) => {
        setState({field:'coordenadas',value:{lat: parseFloat(obj.lat),lng:parseFloat(obj.lng)}});
    }
    
    
    const handleSubmit = async () => {
        if(formVerification() === 0)
            companySignUp();
    }

    return(
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                bgcolor:'background.default',
                pb:5
            }}
        >
            <img src={logo} style={{width:100,marginBottom:40,marginTop:50}} alt='QUD Logo'/>
            
            <Container maxWidth='md'>
                <Grid container>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Registro de Empresa
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="razonSocial"
                                        name='razonSocial'
                                        label="Razon Social"
                                        placeholder='Razon Social'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <WorkIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {razonSocial}
                                        onChange = { onChange }
                                        onBlur={ () => (razonSocial === '' ? setState({field:'errorRazonSocial',value:true}) : setState({field:'errorRazonSocial',value:false}) ) }
                                        error = {errorRazonSocial}
                                        helperText={errorRazonSocial && 'Ingrese la razon social de la empresa'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="nombreComercial"
                                        name='nombreComercial'
                                        label="Nombre Comercial"
                                        placeholder='Nombre Comercial'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <StorefrontIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {nombreComercial}
                                        onChange = { onChange }
                                        onBlur={ () => (nombreComercial === '' ? setState({field:'errorNombreComercial',value:true}) : setState({field:'errorNombreComercial',value:false}) ) }
                                        error = {errorNombreComercial}
                                        helperText={errorNombreComercial && 'Ingrese el nombre comercial de la empresa'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container>
                                        <Grid item xs={3} sm={3} md={4} lg={2} xl={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="rifPrefix"
                                                    name='rifPrefix'
                                                    label=""
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ArticleIcon />
                                                        </InputAdornment>
                                                        ),
                                                    }}
                                                    value={rifPrefix}
                                                    onChange = { onChange }
                                                    //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                                    //error = {errorCategoria}
                                                    //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                                >
                                                    <MenuItem value='J'>J</MenuItem>
                                                    <MenuItem value='V'>V</MenuItem>
                                                    <MenuItem value='E'>E</MenuItem>
                                                    <MenuItem value='G'>G</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={8} lg={10} xl={10}>
                                            <TextField
                                                fullWidth
                                                id="rif"
                                                name='rif'
                                                label="RIF"
                                                placeholder='RIF'
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ArticleIcon />
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value = {rif}
                                                onChange = { onChange }
                                                onBlur={ () => (rif === '' ? setState({field:'errorRif',value:true}) : setState({field:'errorRif',value:false}) ) }
                                                error = {errorRif}
                                                helperText={errorRif && 'Ingrese el RIF de la empresa'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            fullWidth
                                            select
                                            id="categoria"
                                            name='categoria'
                                            label="Categoria"
                                            placeholder='Seleccione una categoria'
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <CorporateFareIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            value = {categoria}
                                            onChange = { onChange }
                                            onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                            error = {errorCategoria}
                                            helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                        >
                                            <MenuItem value=''>Seleccione una Categoria</MenuItem>
                                            {
                                                categorias.length !== 0 &&
                                                categorias.map((item)=>
                                                    <MenuItem value={item.idcategoria}>{item.nombre}</MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </FormControl>   
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="descripcion"
                                        name='descripcion'
                                        label="Descripcion"
                                        placeholder='Descripcion'
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <ArticleIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {descripcion}
                                        onChange = { onChange }
                                        onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                        error = {errorDescripcion}
                                        helperText={errorDescripcion && 'Ingrese la descripcion de su empresa'}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container style={{marginTop:70}}>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Ubicacion / Contacto
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div style={{width:'100%',height:400,marginBottom:20}}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU' }}
                                            center={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                            //defaultCenter={{lat:coordenadas.lat, lng:coordenadas.lng}}
                                            defaultZoom={14}
                                            onClick={(obj)=>onMapClick(obj)}
                                        >
                                            <GpsFixedIcon lat={coordenadas.lat} lng={coordenadas.lng} style={{fontSize:30}} color='primary'/>
                                        </GoogleMapReact>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        id="ciudad"
                                        label="Ciudad"
                                        name='ciudad'
                                        placeholder='Seleccione una ciudad'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationCityIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {ciudad}
                                        onChange = { onChange }
                                        onBlur={ () => (ciudad === '' ? setState({field:'errorCiudad',value:true}) : setState({field:'errorNombreComercial',value:false}) ) }
                                        error = {errorCiudad}
                                        helperText={errorCiudad && 'Seleccione la ciudad en donde se encuentra ubicada la empresa'}
                                    >
                                        <MenuItem value=''>Seleccione una Ciudad</MenuItem>
                                        {
                                            ciudades.length !== 0 &&
                                            ciudades.map((item)=>
                                                <MenuItem value={item.idCiudad}>{item.nombre}</MenuItem>
                                            )
                                        }
                                    </TextField> 
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="direccion"
                                        label="Direccion"
                                        name='direccion'
                                        multiline
                                        rows={2}
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {direccion}
                                        onChange = { onChange }
                                        onBlur={ () => (direccion === '' ? setState({field:'errorDireccion',value:true}) : setState({field:'errorDireccion',value:false}) ) }
                                        error = {errorDireccion}
                                        helperText={errorDireccion && 'Ingrese la direccion de su empresa'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="telefono"
                                        label="Telefono"
                                        name='telefono'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneEnabledIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {telefono}
                                        onChange = { onChange }
                                        onBlur={ () => (telefono === '' ? setState({field:'errorTelefono',value:true}) : setState({field:'errorTelefono',value:false}) ) }
                                        error = {errorTelefono}
                                        helperText={errorTelefono && 'Ingrese el numero de telefono de su empresa'}
                                    />    
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                {/**DATOS DEL RESPONSABLE */}
                <Grid container style={{marginTop:70}}>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Responsable
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="nombre"
                                        label="Nombres"
                                        name='nombre'
                                        placeholder='Nombres'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {nombre}
                                        onChange = { onChange }
                                        onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                        error = {errorNombre}
                                        helperText={errorNombre && 'Ingrese el nombre de la persona encargada de su empresa'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        id="apellido"
                                        label="Apellidos"
                                        name='apellido'
                                        placeholder='Apellidos'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value = {apellido}
                                        onChange = { onChange }
                                        onBlur={ () => (apellido === '' ? setState({field:'errorApellido',value:true}) : setState({field:'errorApellido',value:false}) ) }
                                        error = {errorApellido}
                                        helperText={errorApellido && 'Ingrese el apellido de la persona encargada de su empresa'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container>
                                        <Grid item xs={3} sm={3} md={4} lg={2} xl={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="cedulaPrefix"
                                                    name='cedulaPrefix'
                                                    label=""
                                                    placeholder='Seleccione una categoria'
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                        <InputAdornment position="start">
                                                            <ArticleIcon />
                                                        </InputAdornment>
                                                        ),
                                                    }}
                                                    value={cedulaPrefix}
                                                    onChange = { onChange }
                                                    //onBlur={ () => (categoria === '' ? setState({field:'errorCategoria',value:true}) : setState({field:'errorCategoria',value:false}) ) }
                                                    //error = {errorCategoria}
                                                    //helperText={errorCategoria && 'Seleccione la categoria de la empresa'}
                                                >
                                                    <MenuItem value='V'>V</MenuItem>
                                                    <MenuItem value='E'>E</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={8} lg={10} xl={10}>
                                            <TextField
                                                fullWidth
                                                id="cedula"
                                                label="Cedula"
                                                name='cedula'
                                                placeholder='Cedula'
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ArticleIcon />
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value = {cedula}
                                                onChange = { onChange }
                                                onBlur={ () => (cedula === '' ? setState({field:'errorCedula',value:true}) : setState({field:'errorCedula',value:false}) ) }
                                                error = {errorCedula}
                                                helperText={errorCedula && 'Ingrese el numero de cedula'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>


                <Grid container style={{marginTop:70}}>
                    <Typography variant='h6' style={{textAlign:'center',marginBottom:10}}>
                        Datos de Acceso
                    </Typography>
                    <Grid item lg={12}>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Correo"
                                        placeholder='Correo'
                                        name='email'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        value={email}
                                        onChange = { onChange }
                                        onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                        error = {errorEmail}
                                        helperText={errorEmail && 'Ingrese el correo electronico'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name='password'
                                        label="Contrasena"
                                        placeholder='Contrasena'
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        type="password"
                                        value={password}
                                        onChange = { onChange }
                                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                        error = {errorPassword}
                                        helperText={errorPassword && 'Ingrese la contrasena'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="passwordConfirm"
                                        name='passwordConfirm'
                                        label="Confirmar Contrasena"
                                        placeholder='Confirmar Contrasena'
                                        variant="outlined"
                                            InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        type="password"
                                        value={passwordConfirm}
                                        onChange = { onChange }
                                        onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                                        error = {errorPasswordConfirm}
                                        helperText = {passwordConfirm === '' && 'Ingrese la Confirmación de Contraseña', passwordConfirm !== password && 'La contrasena no coincide'}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>
                    <Checkbox
                            checked={agree} 
                            onChange={()=>{ 
                                setState({field:'agree',value:!agree})} 
                            }
                            name="agree"
                            id='agree' 
                            onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                        />
                    <Typography>Declaro que he leído y acepto los <Link component='a' href='https://anzteenamora.com/TerminosCondicionesATE.pdf' target='_blank'>Términos y Condiciones</Link> de uso de Anzoátegui Te Enamora. </Typography>
                </div>
                {
                    errorAgree === true
                    ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso del CIVO</Typography>
                    :null
                }

                <Grid container style={{marginTop:30}}>
                    <Grid item lg={3} xl={3}>
                        <LoadingButton
                            variant='contained'
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            Registrar Empresa
                        </LoadingButton>
                    </Grid>
                </Grid>

            </Container>
            <div style={{marginTop:30,margonBottom:30}}>
                <Copyright/>
            </div>

            {/** mensaje de error si el login es fallido **/}
            <Dialog
                open={errorSignIn}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    {errorSignInMsg}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" style={{color:'#000'}}>
                        Cerrar
                    </Button>
                </DialogActions>
              </Dialog>
            <Snackbar open={errorCoordenadas} autoHideDuration={6000} onClose={() => setState({field:'errorCoordenadas',value:false})}>
                <Alert variant='filled' severity="error">
                <AlertTitle>Error</AlertTitle>
                    Debe indicar la ubicación de la empresa o negocio en el mapa 
                </Alert>
            </Snackbar>
            <Dialog
                open={registrado}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={"sm"}
                
                onClose={()=>history.replace('/')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Usuario Registrado Exitosamente</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{color:'#000'}}>
                    Su usuario ha sido registrado exitosamente.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>history.replace('/')} color="primary" style={{color:'#000'}}>
                        Iniciar sesion
                    </Button>
                </DialogActions>
              </Dialog>
        </Box>
    )
}