import ROUTES from '../../navigation/routes';
import { GET, POST } from './ConsumeApi';



export async function GetProductos(comercio_id)
{
    let response = await GET(ROUTES.POS_OFFLINE.COMMERCE.PRODUCTS.GetAll+comercio_id);
    return response;
}


export async function AddProducto(comercio_id,nombre,descripcion,cantidad,precio,ruta_img,categoria)
{
    const params = [
        {name:'comercio_id',value:comercio_id},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'cantidad',value:cantidad},
        {name:'precio',value:precio},
        {name:'ruta_img',value:ruta_img},
        {name:'comercio_categoria_id',value:categoria}
    ];

    let response = await POST(ROUTES.POS_OFFLINE.COMMERCE.PRODUCTS.Add,params);
    return response; 
}

export async function UpdateProducto(product_id,comercio_id,nombre,descripcion,cantidad,precio,ruta_img,categoria)
{
    const params = [
        {name:'comercio_producto_id',value:product_id},
        {name:'comercio_id',value:comercio_id},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'cantidad',value:parseFloat(cantidad)},
        {name:'precio',value:precio},
        {name:'ruta_img',value:ruta_img},
        {name:'comercio_categoria_id',value:categoria}
    ];

    let response = await POST(ROUTES.POS_OFFLINE.COMMERCE.PRODUCTS.Update+product_id,params);
    return response; 
}

export async function ChangeStatus(product_id)
{
    let response = await GET(ROUTES.POS_OFFLINE.COMMERCE.PRODUCTS.ChangeStatus+product_id);
    return response;
}