/**
 * CONSUME API CONTROLLER
 * para la integracion con POS Offline
 */



export async function GET(ruta)
{
    try
    {
        var token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNmQ3YmZiOGI4ODY1ZmE1NTU5NWY3ZWEzZjViZjM0M2YwMzRiNjJkMDQ0MzY0OWYzYzFkMDZkMTIzMWU4NWRlNzRhMTVhZjMxYTQ2NWNmNTgiLCJpYXQiOjE2NTU0NzQ0ODMsIm5iZiI6MTY1NTQ3NDQ4MywiZXhwIjoxNjg3MDEwNDgzLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.WnHKA9XhcQLLHHMB3bJ_1QjETrj0NhfbwlYGLAq4P_KGXfC1iMXOEXGgsySChm1I8iecMSDIpockTMusiAY0cfvYvvo80Jws_vAsK7klCeS-e4YlsnAhoxHGKayE7eQOM279kN2lhlqmnvYrBrUJLwQtz_Wp2zSiiULS8kO2FjD_FO61QGf81KfgNXZ7TAEByE9EJvpwVrbsluNP0KIYjh6U-uWDkZ36WCar_ahbLc6HMdZbhJ8YuvtQzjLZJ-qEPYXUp7TeFIAK2x_-nIaajUQvdsOW7uOo50ZcKpGkI_GP1jmwKHWdQHgXkkvXarSVVFWtf0DUhjcuCEJ2x1N8w7PDtLEztDZgbbL5bg-PecaRkIMnub4RSXA8UZtZbznq_efvOfmVgrR91KwbLFBzkjCnAJ2ufvzAP6cIEkEg7F6Vxatg5C8tPJkke1Iexqil_2wD0tHwe8JKFuy6QQ5IZ9gZt5ctXeqCibbzv9neRrd53OGCTnelt6N58wpKn6h_MBZWx7qG0KJqRsmFV7FM3Jl2-7R7XDk668tpDV03uyZLq3T9RoouytX5vMlz6-BIr8WsrKp_R_d-7_xpvIOG0ebOgXXD9HjaCVdU1MZz2Qk5v6PkzgvOiojKagJJpiaWAcQP-kdlp7tcT7eFu2K3jXdf5BWBVEF4TzzDOfsjuuI';

        let response = await fetch(
            ruta,
            {
                method : 'GET',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                }
            }
        );

        let responseJson = await response.json();
        //console.log(responseJson);

        if(responseJson.success === true )
        {
            return responseJson;
        }

        if(responseJson.success === false)
        {
            return responseJson.message;
        }

    }
    catch(error)
    {
        return error;
    }
}


export async function POST(ruta,parametros)
{
    try 
    {
        var token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjUyMjQwZGNkN2U3NDNkODllYmZjMjAwMGQzNTAwOTRkNmEzZGUwZTBlMTU5ZjhmNGFjOThjYTY1OTA0NmEwZjVlYTE1MTFhN2MzYjg1NTciLCJpYXQiOjE2NTU0NzMwOTAsIm5iZiI6MTY1NTQ3MzA5MCwiZXhwIjoxNjg3MDA5MDkwLCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.Xmy9i_joKs7MlDDXxcSg8XKklN2fZEtWV-2C5GD8fgoQgE4OOCBor0xMIwRk2D717e2rVE-_K6-CRBKsTXwgyCn4jguu775I-OSt0DXHuaH0oHnQBRl4LCboU59UgbLPlOU9MHX1vsyghIgTLkepxjtZ0g8_zZ-K8Qu_m5HLM3E-NiC2uNPmWLNNg4AKpSa1makSTsUwpStqM7n7O8z8SEx9c-9Tx3jnXY-fFP2qX8Nyl7geNFNepqShtO0BTKW9duPB6cGl9EZTLDD1hPkzBfj9ayXQDRcGDNSOFhzGVutC0IrcoR8gJ1r27JBT2AjIXmO3RZ7xl8TwENzC2bUBfNqL8cwFSnbHadCf8woJ3EcrrC-Hxl6oOZHmwoMj8F5IrBqMWNB7AFfuhAIyz_IKB_1tHaoOWwTGJdVD6NvsjHgkDE-sDL_iziGKwxbD34HglmrzP95qvtrYF6kn0n1mI7D_Fhgr4Vz0DVCd4VhqsasXMqbT2C7BBoEnY00rjgESgKWgYcMqic3OqP7CgzLXWTM7lOIQllSqsCl7LRbSW37_Ddhl-VDmUUgxyCo4bwDsb2hpLdQPlDRIFboCpuO7Ma3f7uNOPdoz91XMDJExRQMACVvj6l_WGxkJlT7KPM7HDf4DeQmd1Qqcmyo_GOREd5XhpwAb2_bSCHgoPyJFj0k';

        let params = new FormData();

        parametros.forEach((p)=>{
            params.append(p.name,p.value);
        });


        let response = await fetch(
            ruta,
            {
                method : 'POST',
                headers : 
                {
                    'Accept' : 'application/json',
                    'Authorization' : token,
                },
                body : params
            }
        );

        let responseJson = await response.json();
        console.log(responseJson);

        if(responseJson.success === true)
        {
            return responseJson;
        }

        if(responseJson.success === false)
        {
            return responseJson;
        }
        
    } 
    catch (error) 
    {
        return error;
    }
}
