import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, TextField, Button, IconButton } from "@mui/material";
import {LoadingButton} from '@mui/lab';

import ReactInputVerificationCode from 'react-input-verification-code';

import logo from '../../assets/img/logo.jpg';
import ThemeMode from "../../assets/theme/theme";
import ColorModeContext from "../../assets/theme/ColorModeContext";
import Copyright from "../../components/copyRight";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ForgotPassword()
{
    const [isLoading,setIsLoading] = useState(false);
    const [step, setStep] = useState(0);

    const history = useHistory()
    const redirect = (route) => {
        history.push(route)
    }
    

    return(
        <Box
            sx={{
                height:'100vh',
                display:'flex',
                flexDirectio:'row',
                bgcolor:'background.default'
            }}
        >
            <Grid container style={{display:'flex', flexDirection:'row',justifyContent:'center'}}>
                <Grid item xs={12} lg={6} style={{height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',position:'relative'}}>
                    <div style={{position:'absolute',top:20,left:20}}>
                        <IconButton onClick={ () => history.goBack()} >
                            <ArrowBackIcon/>
                        </IconButton>
                    </div>
                    <div style={{position:'absolute',top:20,right:20}}>
                        <ThemeMode mode={ColorModeContext}/>
                    </div>
                    <Grid container spacing={3} style={{justifyContent:'center'}}>
                        <Grid item lg={8}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '100%',borderColor:'#FFF' },
                                    '& .MuiLoadingButton-root':{m:1},
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    marginBottom:3
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <img src={logo} style={{width:60}} alt='QUD Logo'/>
                                {
                                    step === 0 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Recuperar Contraseña
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            Para recuperar la contrasena ingresa tu correo electronico y te enviaremos
                                            un codigo de recuperacion
                                        </Typography>
                                        <TextField
                                            color='primary'
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                        />
                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                        >
                                            Enviar Codigo
                                        </LoadingButton>
                                    </>
                                }

                                {
                                    step === 1 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Codigo de Verificacion
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            Ingresa el codigo de verificacion que has recibido por correo electronico
                                        </Typography>
                                        
                                        <Box sx={{color:'text.primary',mb:4}}>
                                            <ReactInputVerificationCode />
                                        </Box>

                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                        >
                                            Verificar Codigo
                                        </LoadingButton>
                                    </>
                                }


                                {
                                    step === 2 &&
                                    <>
                                        <Typography variant='h5' color='text.primary' style={{marginTop:20,marginBottom:20, textAlign:'center'}}>
                                            Recuperar Contraseña
                                        </Typography>
                                        <Typography color='text.primary' style={{marginTop:20,marginBottom:40, textAlign:'center'}}>
                                            Para recuperar la contrasena ingresa tu correo electronico y te enviaremos
                                            un codigo de recuperacion
                                        </Typography>
                                        <TextField
                                            color='primary'
                                            id="outlined-basic"
                                            label="Nueva contraseña"
                                            variant="outlined"
                                        />

                                        <TextField
                                            color='primary'
                                            id="outlined-basic"
                                            label="Confirmar nueva contraseña"
                                            variant="outlined"
                                        />
                                        <LoadingButton
                                            color='primary'
                                            loading={isLoading}
                                            fullWidth
                                            variant="contained"
                                        >
                                            Recupera Contraseña
                                        </LoadingButton>
                                    </>
                                }
                                
                            </Box>
                            
                        </Grid>
                    </Grid>
                    <div style={{position:'absolute',bottom:10}}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
                            <Copyright/>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}